<template>
    <div class="login-container">
        <el-form ref="loginForm" :model="loginForm" class="login-form" auto-complete="on" label-position="left">
            <div class="title-container">
                <img src="../assets/menu-logo.png" class="image" alt="">
                <span class="title">{{title}}</span>
            </div>
            <div>
                <el-form-item prop="username">
                    <span class="svg-container">
                        <svg-icon icon-class="user"/>
                    </span>
                    <el-input
                            ref="username"
                            v-model="loginForm.account"
                            placeholder="请输入用户名"
                            name="username"
                            type="text"
                            tabindex="1"
                            auto-complete="on">
                    </el-input>
                </el-form-item>

                <el-form-item prop="password">
                    <span class="svg-container">
                        <svg-icon icon-class="password"/>
                    </span>
                    <el-input
                            :key="passwordType"
                            ref="password"
                            v-model="loginForm.password"
                            :type="passwordType"
                            placeholder="请输入密码"
                            name="password"
                            tabindex="2"
                            auto-complete="on">
                    </el-input>
                    <span class="show-pwd" @click="showPwd">
                        <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"/>
                    </span>
                </el-form-item>

                <div class="identify-container">
                    <el-input
                            class="identify el-form-item"
                            v-model="identifyInput"
                            maxlength="4"
                            placeholder="请输入验证码"
                            tabindex="3"
                            @keyup.enter.native="handleLogin">

                    </el-input>

                    <identify class="code" :contentHeight="43" :identifyCode="identify" @onClick="randomCode"/>
                </div>

                <el-button :loading="loading" type="primary" class="button"
                           @click.native.prevent="handleLogin">登录
                </el-button>
            </div>
        </el-form>

      <div style="position: absolute; bottom: 50px; left: 50%; transform: translateX(-50%)">Copyright © 2022 趣链科技 版权所有 <a href="https://beian.miit.gov.cn/" style="color:blue">鄂ICP备18005834号-2</a></div>
    </div>
</template>

<script>
    import identify from '@/components/Identify/index'
    import config from '@/config'

    export default {
        name: 'Login',
        components: {
            identify
        },
        data() {
            return {
                loginForm: {
                    account: '',
                    password: ''
                },
                title: config.loginTitle,
                identifyInput: '', // 用户输入的验证码
                identify: '', // 生成的验证码
                loading: false,
                passwordType: 'password',
                redirect: undefined,
            }
        },
        mounted() {
            this.randomCode();
        },
        watch: {
            $route: {
                handler: function (route) {
                    this.redirect = route.query && route.query.redirect
                },
                immediate: true
            }
        },
        methods: {
            showPwd() {
                if (this.passwordType === 'password') {
                    this.passwordType = ''
                } else {
                    this.passwordType = 'password'
                }
                this.$nextTick(() => {
                    this.$refs.password.focus()
                })
            },
            // 生成一个验证码
            randomCode() {
                let code = '';
                for (let i = 0; i < 4; i++) {
                    code += Math.floor(Math.random() * 10);
                }
                this.identify = code;
            },
            handleLogin() {
                if (this.identifyInput === this.identify) {
                    if (this.loginForm.account === "") {
                        this.$message.warning("请输入用户名")
                    } else if (this.loginForm.password === "") {
                        this.$message.warning("请输入密码")
                    } else {
                        this.loading = true;
                        this.$store.dispatch('login/login', this.loginForm).then((response) => {
                            this.$router.push({path: this.redirect || '/'});
                            this.loading = false
                        }).catch(() => {
                            this.loading = false
                        })
                    }

                } else if (!this.identifyInput) {
                    this.$message.warning("请输入验证码")
                } else {
                    this.$message.warning("验证码输入错误")
                }
            }
        }
    }
</script>

<style lang="scss">
    /* 修复input 背景不协调 和光标变色 */
    /* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

    $bg: #283443;
    $light_gray: #fff;
    $cursor: #909399;

    @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
        .login-container .el-input input {
            color: $cursor;
        }
    }

    /* reset element-ui css */
    .login-container {
        .el-input {
            display: inline-block;
            height: 45px;
            width: 85%;

            input {
                border: 0;
                -webkit-appearance: none;
                border-radius: 0;
                padding: 12px 5px 12px 15px;
                color: #303133;
                height: 43px;
                caret-color: $cursor;

                &:-webkit-autofill {
                    box-shadow: 0 0 0 1000px $light_gray inset !important;
                    -webkit-text-fill-color: $cursor !important;
                }
            }
        }

        .el-form-item {
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: #ffffff;
            border-radius: 5px;
            color: #454545;
        }
    }
</style>
<!--$bg:#2d3a4b;-->
<style lang="scss" scoped>
    .login-container {
        width: 100%;
        min-width: 600px;
        min-height: 100%;
        background: url("../assets/login.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .login-form {
            width: auto;
            min-width: 480px;
            max-width: 100%;
            padding: 50px;
            border: 1px solid #C5D1D9;
            background-color: #DCE5EE;

            .title-container {
                display: flex;
                align-items: center;
                margin-bottom: 36px;
                justify-content: center;

                .image {
                    width: 30px;
                    height: 30px;
                }

                .title {
                    font-size: 30px;
                    color: #000;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }

            .svg-container {
                padding: 6px 5px 6px 15px;
                color: #889aa4;
                vertical-align: middle;
                width: 30px;
                display: inline-block;
            }

            .show-pwd {
                position: absolute;
                right: 10px;
                top: 7px;
                font-size: 16px;
                color: #889aa4;
                cursor: pointer;
                user-select: none;
            }

            .identify-container {
                position: relative;

                .identify {
                    width: calc(100% - 130px);
                    line-height: 43px;
                }

                .code {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .button {
                width: 100%;
                margin-top: 10px
            }
        }
    }
</style>
